import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { saveAs } from 'file-saver';

import Doc from '../../documents/nbkc bank - Reg E Error Resolution (for Statement) - Envel vNov2021.pdf';
import Layout from '../../components/Layout';
import MetaData from '../../components/meta/MetaData';
import { Body, CrowdLink } from '../../styles';

const Download = ({ location }) => {
	useEffect(() => {
		saveAs(Doc, 'nbkc bank - Reg E Error Resolution (for Statement) - Envel vNov2021.pdf');
	}, []);

	const downloadFile = () => {
		saveAs(Doc, 'nbkc bank - Reg E Error Resolution (for Statement) - Envel vNov2021.pdf');
	};

	return (
		<Layout>
			<MetaData
				title="Envel - nbkc bank Reg E Error Resolution (for Statement)"
				description="nbkc bank Reg E Error Resolution (for Statement) - Envel November 2021"
				image="images/social/social.png"
				location={location}
			/>

			<div style={{ height: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Body center style={{ width: '90%' }}>
					Thank you for downloading nbkc bank Reg E Error Resolution (for Statement) - Envel November 2021. If it did
					not download automatically, please click <CrowdLink onClick={downloadFile}>here</CrowdLink>
					.
					<br />
					<br /> Return <Link to="/">home.</Link>
				</Body>
			</div>
		</Layout>
	);
};

export default Download;
